<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Modules</b-card-title>
      <b-card-sub-title>Modules according to whitelabels</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  setup() {
    const permissionsData = [
      {
        module: 'Users',
       
      },
      {
        module: 'Roles',
       
      },
      {
        module: 'Permission',
       
      },
      {
        module: 'Clients',
       
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
