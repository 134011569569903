<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="data === undefined"
    >
      <h4 class="alert-heading">
        Error fetching whitelabel data
      </h4>
      <div class="alert-body">
        No whitelabel found with this whitelabel id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-whitelabels-list'}"
        >
          Whitelabel List
        </b-link>
        for other whitelabel.
      </div>
    </b-alert>

    <template v-if="data">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <whitelabel-view-whitelabel-info-card :data="data" />
        </b-col>
        </b-row>
        <!-- <b-row>
            <b-col
            cols="12"
            lg="12"
            >
            <whitelabel-view-whitelabel-permissions-card />
            </b-col>
        </b-row> -->

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import whitelabelStoreModule from '../whitelabelStoreModule'
import WhitelabelViewWhitelabelInfoCard from './WhitelabelViewWhitelabelInfoCard'
import WhitelabelViewWhitelabelPermissionsCard from './WhitelabelViewWhitelabelPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    WhitelabelViewWhitelabelInfoCard,
    WhitelabelViewWhitelabelPermissionsCard,

    InvoiceList,
  },
  setup() {
    const data = ref(null)

    const ROLE_APP_STORE_MODULE_NAME = 'app-whitelabel'

    // Register whitelabel
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, whitelabelStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-whitelabel/fetchWhitelabel', { id: router.currentRoute.params.id })
      .then(response => { 
        data.value = response.data.data })
      .catch(error => {
          data.value = undefined
      })

    return {
      data,
    }
  },
}
</script>

<style>

</style>
