<template>
  <b-card>

    <b-row>

      <!-- Role Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <!--  & Action Buttons -->
        <div class="d-flex justify-content-start">
          
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ data.name }}
              </h4>
              
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-whitelabels-edit', params: { id: data._id } }"
                variant="primary"
              >
                Edit
              </b-button>
             
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="8"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ data.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Host</span>
            </th>
            <td class="pb-50">
              {{ data.host }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ServerIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Type</span>
            </th>
            <td class="pb-50">
              {{ data.type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UsersIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Users</span>
            </th>
            <td class="pb-50">
              {{ data.users }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FileTextIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Description</span>
            </th>
            <td class="pb-50">
              {{ data.description }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ListIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Currencies</span>
            </th>
            <td class="pb-50 pt-75">
              <p 
                v-for="item in data.currencies"
                :key="item"
              >
               {{ item }}
              </p>
            </td>
          </tr>
          <tr v-if="data.products.length > 0">
            <th class="pb-50">
              <feather-icon
                icon="ListIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Products</span>
            </th>
            <td class="pb-50">
              <p 
                v-for="item in data.products"
                :key="item._id"
              >
               {{ item.name }}
              </p>
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
//   setup() {
//     const { resolveModuleRoleVariant } = useModulesList()
//     return {
      
     
//     }
//   },
  mounted()
  {
    console.log(this.data)
  }
}
</script>

<style>

</style>
